<template>
  <HeaderView />
  <SloganView />
  <MainFeatureView />
  <FooterView />
<!-- <TestView /> -->
</template>

<script>

import HeaderView from "./HeaderView.vue"
import SloganView from "./body/SloganView.vue"
import MainFeatureView from "./body/MainFeatureView.vue"
import FooterView from "./FooterView.vue"
// import TestView from "./TestView.vue"


export default {
  components: {
    HeaderView,
    FooterView,
    SloganView,
    MainFeatureView,
    // TestView
  },
}
</script>