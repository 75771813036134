<template>
    <transition name="popupTransition" :appear="true">
        <Popup v-show="showPopup" @closePopup="closePopup"></Popup>
    </transition>
    <header>
        <div class="headerContainer">

            <div>
                <span class="pathText">PATH</span>
            </div>
            <div>
                <button class="navButton" :class="{ 'hidden': !isScrolled }" @click="showPopupHandle">
                    下载或预约
                </button>
                <button class="navButton" @click="openPlogLink">
                    小红书
                </button>
                <!-- <button class="navButton" @click="openLink">
                    B站
                </button> -->
                <button class="navButton" @click="openRouteNotionLink">
                    路线
                </button>
                <a href="mailto:hipathteam@gmail.com?subject=&body=" class="navButton">联系</a>
            </div>
        </div>

    </header>
</template>

<script>

import Popup from "@/components/body/ReservePopup.vue"

export default {
    components: {
        Popup
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0);
    },
    data() {
        return {
            showPopup: false,
            isScrolled: false
        }
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 450) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
        },
        showPopupHandle() {
            this.showPopup = true;
            if (window.innerWidth < 500) {
                this.disableScroll();
            }
        },
        disableScroll() {
            document.documentElement.style.overflowY = 'hidden';
        },
        enableScoll() {
            document.documentElement.style.overflowY = 'auto';
        },
        closePopup() {
            this.showPopup = false;
            if (window.innerWidth < 500) {
                this.enableScoll()
            }
        },
        openLink() {
            const url = 'https://space.bilibili.com/366860913?spm_id_from=333.1007.0.0';
            window.open(url, '_blank');
        },
        openPlogLink() {
            const url = 'https://www.xiaohongshu.com/user/profile/62bd05be000000001b025eaa';
            window.open(url, '_blank');
        },
        openRouteNotionLink() {
            const url = 'https://pathwiki.notion.site/842b135b2db344ce89c7109ebc7519f0?v=405c010233ff49939fe32f1e97ed1dd5&pvs=4';
            window.open(url, '_blank');
        }
    },
}
</script>

<style scoped>
/* 小于或等于生效 */
@media screen and (max-width: 800px) {}

.headerContainer {

    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    padding-left: 28px;
    padding-right: 14px;
    height: 54px;
    /* background-color: #fcfdff; */
    /* background-color: rgba(250, 250, 250, 0.50); */
    background-color: var(--navBar-bg);
    backdrop-filter: blur(25px);

    /* transition: border-color 0.20s ease 0s; */
    /* transition: background-color 0.20s ease 0s; */
    border-color: transparent;
    border-bottom-width: 0.5px;
    /* border-bottom-color: #D8D8D8; */
    border-bottom-color: var(--navBar-border);
    border-style: solid;

    /* transition: background-color 0.20s ease 0s; */
    /* transition: border-color 0.20s ease 0s; */
}



.pathText {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.1em;
    color: var(--navBar-logo);
}

.navButton {
    background-color: transparent;
    /* color: #AFB4BE; */
    color: var(--navBar-button);
    font-size: 16px;
    font-weight: 400;
    border: 0;
    padding: 0 8px;
    transition: color 0.15s ease 0s;
    cursor: pointer;
    text-decoration: none;
}

.hidden {
    visibility: hidden;
    color: transparent;
    transition: color 0.20s ease 0s;
}

.navButton div {
    text-decoration: none;
}

@media screen and (min-width: 500px) {
    .navButton {
        padding: 0 14px;
    }

    .navButton:hover {
        color: #8c929c;
        transition: color 0.15s ease 0s;
    }

    .navButton:active {
        color: var(--navBar-button);
        transition: color 0.15s ease 0s;
    }
}

.popupTransition-enter-active {
    animation: showFormBottom 0.5s cubic-bezier(0.00, 0.00, 0.40, 1.00);
}

.popupTransition-leave-active {
    animation: showFormBottom 0.5s cubic-bezier(0.00, 0.00, 0.40, 1.00) reverse;
}

@keyframes showFormBottom {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0px);
    }
}
</style>