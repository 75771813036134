<template>
    <div>
        <div class="featureContainer">
            <div class="fetureLabel">
                <h2 class="featureFirstText">上层目标</h2>
                <div class="featureSecondText">以终为始，找到真正的目标，做对的事。“谋定而后动，知止而有得”。</div>
            </div>
            <div class="videoContainer">
                 <video loop muted autoplay playsinline :key="darkMode">
                    <source :src="upGoalVideoPathWebm" type="video/webm">
                    <source :src="upGoalVideoPathMp4" type="video/mp4">
                </video>
            </div>
        </div>
        <div class="featureContainer">
            <div class="fetureLabel">
                <h2 class="featureFirstText">无限拆解</h2>
                <div class="featureSecondText">大目标拆成小目标，小目标拆成更小的目标，实现路径越拆越清晰。</div>
            </div>

            <div class="videoContainer">
                <video loop muted autoplay playsinline :key="darkMode">
                    <source :src="subGoalVideoPathMp4" type="video/webm">
                    <source :src="subGoalVideoPathMp4" type="video/mp4">
                </video>
            </div>
        </div>
        <div class="featureContainer">
            <div class="fetureLabel">
                <h2 class="featureFirstText">备用方案</h2>
                <div class="featureSecondText">为不确定性强的目标添加备用方案，有效提升实现率。</div>
            </div>

            <div class="videoContainer">
                <video loop muted autoplay playsinline :key="darkMode">
                    <source :src="planVideoPathWebm" type="video/webm">
                    <source :src="planVideoPathMp4" type="video/mp4">
                </video>
            </div>
        </div>
        <div class="featureContainer">
            <div class="fetureLabel">
                <h2 class="featureFirstText">日程时间+最后期限</h2>
                <div class="featureSecondText">什么时间做？什么时间要做完？使用Path轻松设置，一目了然。</div>
            </div>

            <div class="videoContainer">
                <video loop muted autoplay playsinline :key="darkMode">
                    <source :src="timeVideoPathWebm" type="video/webm">
                    <source :src="timeVideoPathMp4" type="video/mp4">
                    
                </video>
            </div>
        </div>
        <!-- <div class="featureContainer">
            <div class="fetureLabel">
                <h2 class="featureFirstText">目标蓝图</h2>
                <div class="featureSecondText">在无限延展的画布上纵观全局，你的大规划或小目标，都由你来掌握。</div>
            </div>

            <div class="videoContainer">
                <img :src="imagePath" alt="logo image" />
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches
        }
    },
    mounted() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            this.darkMode = e.matches;
        });
    },
    computed: {
        // imagePath() {
        //     return this.darkMode ? 'assets/heroImageBlack.png' : 'assets/heroImageWhite.png';
        // },
        upGoalVideoPathWebm() {
            return this.darkMode ? 'assets/upGoalVideoBlack2.webm' : 'assets/upGoalVideoWhite2.webm'
        },
        upGoalVideoPathMp4() {
            return this.darkMode ? 'assets/upGoalVideoBlack2.mp4' : 'assets/upGoalVideoWhite2.mp4'
        },
        subGoalVideoPathWebm() {
            return this.darkMode ? 'assets/subGoalVideoBlack2.webm' : 'assets/subGoalVideoWhite2.webm'
        },
        subGoalVideoPathMp4() {
            return this.darkMode ? 'assets/subGoalVideoBlack2.mp4' : 'assets/subGoalVideoWhite2.mp4'
        },
        planVideoPathWebm() {
            return this.darkMode ? 'assets/planVideoBlack2.webm' : 'assets/planVideoWhite2.webm'
        },
        planVideoPathMp4() {
            return this.darkMode ? 'assets/planVideoBlack2.mp4' : 'assets/planVideoWhite2.mp4'
        },
        timeVideoPathWebm() {
            return this.darkMode ? 'assets/timeVideoBlack2.webm' : 'assets/timeVideoWhite2.webm'
        },
        timeVideoPathMp4() {
            return this.darkMode ? 'assets/timeVideoBlack2.mp4' : 'assets/timeVideoWhite2.mp4'
        },
    }
}
</script>

<style scoped>

.featureContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    /* background-color: antiquewhite; */
}
@media screen and (max-width: 500px) {
    .featureContainer {
        margin-top: 0px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.fetureLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;

    padding-left: 32px;
    padding-right: 32px;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    gap: 12px;
}
@media screen and (max-width: 500px) {
    .fetureLabel {
        max-width: none;
        padding-left: 0px;
        /* margin-right: 110px; */
        margin-left: 32px;
        margin-top: 0px;
        gap: 6px;
    } 
}

.featureFirstText {
    /* color: #AFB4BE; */
    color: var(--navBar-button);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 160px;
}
@media screen and (max-width: 500px) {
    .featureFirstText {
        font-size: 16px;
        margin-top: 100px;
    }
}

.featureSecondText {
    /* color: black; */
    color: var(--mainFeature-secondTitle);
    font-size: 24px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
}
@media screen and (max-width: 500px) {
    .featureSecondText {
        font-size: 16px;
    }
}

.videoContainer {
    display: flex;
    justify-content: center;

    margin-top: 0px;
    margin-bottom: 0;
    padding: 12px;
}
@media screen and (max-width: 800px) {
    
}

.videoContainer video {
    display: block;
    width: 100%;
    box-shadow: 0px 6px 12px 6px rgba(93, 93, 93, 0.34);
    transition: all 0.15 ease 0s;

    max-width: 1120px;
    border-radius: 10px;

    @media (prefers-color-scheme: dark) {
        box-shadow: none;
        transition: all 0.15 ease 0s;
    }

}
@media screen and (max-width: 800px) {
    .videoContainer video {
        border-radius: 2px;
        max-width: none;
    }
}

.videoContainer img {
    display: block;
    width: 100%;
    box-shadow: 0px 6px 12px 6px rgba(93, 93, 93, 0.34);
    transition: all 0.15 ease 0s;

    max-width: 1120px;
    border-radius: 8px;

    @media (prefers-color-scheme: dark) {
        box-shadow: none;
        transition: all 0.15 ease 0s;
    }

}
@media screen and (max-width: 800px) {
    .videoContainer img {
        border-radius: 4px;
        max-width: none;
    }
}
</style>