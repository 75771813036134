<template>
  <div>
    <HeaderView />
    <div class="contentContainer">
      <h3>用户协议</h3>
      <div v-html="paragraphs" class="content"></div>
    </div>
  </div>
</template>

<script>
import HeaderView from "./HeaderView.vue";

export default {
  components: {
    HeaderView,
  },

  data() {
    return {
      paragraphs: `
            <p>生效日期：2024年5月1日</p>
            <h4>1. 感谢你使用Path</h4>
            <p>《Path用户协议》（以下简称“本协议”）是用户与我们就下载、安装、复制、使用我们的产品和/或服务订立的契约。请在开始使用我们的产品和/或服务之前，认真阅读并充分理解本服务协议。<br/>
如同意本协议，或使用我们的产品，即表示已充分阅读、理解并接受本协议的全部内容，并与我们之间达成一致，成为 Path 用户；如果不同意本协议的任意内容，或者无法准确理解本协议条款的解释，请不要对相关条款内容对应的功能进行任何后续操作，否则，使用及任何操作行为，均视为已接受本协议约束。<br/>
使用 Path 产品及服务将视为您同意以下使用条款。</p>
            <h4>2. Path标准版服务</h4>
            <h5>2.1 会员服务内容</h5>
            <p>使用会员服务期间，你将享有会员权益。详情请参阅App中的订阅界面。</p>
            <p>你理解并同意：Path有权根据运营情况对以上内容在法律允许的范围内进行调整；同时为了优化向你提供的服务，Path可能推出新的服务与功能，新的服务与功能可能会被纳入会员权益或者另行收费。如需了解会员权益的具体内容，请在Path相关功能界面查看。</p>
            <h5>2.2 购买及取消</h5>
            <p>2.2.1 Path标准版是订阅型商品。</p>
            <p>2.2.2 如需取消订阅，请前往苹果官网渠道进行：https://reportaproblem.apple.com</p>
            <h4>3. 知识产权</h4>
            <p>用户承认我们拥有对 Path 的所有权利，包括但不限于所有知识产权。“知识产权”指在专利法、版权法、商业机密法、商标法、反不正当竞争法等法中规定的所有权利以及其中的所有应用、更新、扩展和恢复，无论在现在还是以后在全球范围内实施和生效。用户同意不会修改、改编、翻译 Path、创作 Path 的派生作品、通过反编译、反向工程、反汇编或其它方式从Path 得到源代码。用户同意不会删除、掩盖或更改 Path 公司或任何第三方的版权声明、商标或其它所有权声明。</p>
            <h4>4. 用户注意事项</h4>
            <h5>4.1 软件使用规范</h5>
            <p>用户应当对其使用本软件及相关服务的行为负责，除非法律允许或者经公司事先书面许可，你使用 Path 软件及相关服务不得具有下列行为：<br/>
            使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对 Path 软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。<br/>
            利用或针对 Path 软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：<br/>
            非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；<br/>
            提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；<br/>
            明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；<br/>
            使用未经许可的数据或进入未经许可的服务器/账号；<br/>
            未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；<br/>
            未经许可，企图探查、扫描、测试“便签”系统或网络的弱点或其它实施破坏网络安全的行为；<br/>
            企图干涉、破坏 Path 系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；<br/>
            伪造 TCP/IP 数据包名称或部分名称；<br/>
            对 Path 软件及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码；<br/>
            违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。<br/>
            如果Path有理由认为你的行为违反或可能违反上述约定的，公司可独立进行判断并处理，且有权在不事先通知的情况下终止向你提供服务，并追究相关法律责任。</p>
            <h5>4.2 内容行为规范</h5>
            <p>使用 Path 必须遵守国家法律法规，用户承诺绝对不会通过我们的产品和**/**或服务发送或传播违反国家法律法规及不文明的信息。否则，我们可对用户发布的信息依法或依本协议进行删除或屏蔽，并有权视情节采取警告、限期改正、暂停服务等措施。此类信息包括但不限于：<br/>
            反对宪法所确定的基本原则的；<br/>
            危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、损害国家荣誉和利益的；<br/>
            煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
            破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
            散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
            散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
            恐吓、侮辱、攻击、诽谤他人，侵害他人合法权益的；<br/>
            利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动的；<br/>
            发布任何侵犯他人著作权、商标权等知识产权及其他合法权利的；<br/>
            侵害未成年人合法权益或者损害未成年人身心健康的；<br/>
            散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；<br/>
            含有其他违反法律法规、政策及公序良俗、干扰便签正常运营或侵犯其他你或第三方合法权益内容的其他信息。</p>
            <h4>5. 用户隐私制度</h4>
            <p>尊重用户个人隐私是我们的一项基本政策。Path一般不会公开、编辑或透露用户的注册资料或保存在Path服务中的非公开内容，除非我们在诚信的基础上认为透露这些信息在以下几种情况是必要的：<br/>
            遵守有关法律规定，包括在国家有关机关查询时，提供用户通过 Path 发布的信息内容及其发布时间、互联网地址或者域名。<br/>
            保持维护「Path」公司的商标等知识产权。<br/>
            在紧急情况下竭力维护用户个人和社会大众的隐私安全。<br/>
            Path认为必要的其他情况下。<br/>
            要了解 Path 数据保护措施的具体信息，请阅读 Path 的隐私权政。该政策解释了当您使用 Path 的相关的服务时，Path 会如何处理您的个人信息，以及如何保护您的隐私权。您同意会在遵守 Path 隐私权政策的前提下应用自己的数据。</p>
            <h4>6. 服务变更</h4>
            <p>我们保留随时变更、中断或终止服务而不需通知用户的权利。用户接受Path行使变更、中断或终止服务的权利，Path不需对用户或第三方负责。</p>
            <h4>7. 责任限制</h4>
            <p>用户理解并同意自主选择下载和使用「Path」和所有与「Path」一同提供或通过「Path」提供的第三方软件，风险自负，并且对于因下载或使用「Path」和此类第三方软件而对计算机系统造成的损坏或数据的丢失，用户应承担全部责任。在适用法律允许的最大范围内，Path明确表示不提供任何其他类型的保证，不论使明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。<br/>
            在适用法律允许的最大范围内，Path及其许可人不会向您做出以下声明或担保：<br/>
            您对服务的使用会满足您的需求；<br/>
            您对服务的使用会连续无中断、及时、安全或没有错误；<br/>
            您使用相关服务而获得的任何信息一律准确可靠；<br/>
            作为相关服务的一部分向您提供的任何软件所发生的操作或功能瑕疵将获得修正。<br/>
            在适用法律允许的最大范围内，Path不就因用户使用「Path」引起的，或在任何方面与Path的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。用户上载的行为，即意味着用户或用户代理的著作权人授权Path及其关联公司在「Path」的服务器上保存内容，但用户或原著作权人仍保有上载作品的著作权，且「Path」公司未经用户或原著作权人许可不得将上载作品在其他公开渠道发布。如用户在使用「Path」时涉及到其他Path或其关联公司提供的服务，还应遵守相应的服务条款。</p>
            <h4>8. 保障</h4>
            <p>用户同意保障和维护Path的利益，负责支付由用户违反本服务条款或Path其他服务条款而引起的律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。</p>
            <h4>9. 第三方产品和服务</h4>
            <p>本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。<br/>
            本软件如果使用了第三方的软件或技术，我们将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。如果你没有遵守这些要求，该第三方或者国家机关可能会对你提起诉讼、罚款或采取其他制裁措施，并要求我们给予协助，你应当自行承担法律责任。<br/>
            如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，我们不承担任何责任。我们不对第三方软件或技术提供客服支持，若你需要获取支持，请与第三方联系。</p>
            <h4>10. 法律及协议更新</h4>
            <p>根据国家法律法规变化及网站运营需要，我们有权对本协议条款不时地进行修改，并根据国家法律法规的要求进行公示，修改后的协议在公示后生效并代替原来的协议。你应不时关注并阅读最新版的协议及网站公告。如你不同意更新后的新协议，你有权立即停止使用我们的产品和/或服务。如你继续使用我们的产品和/或服务，即视为完全同意更新后的协议。如果本协议中任何一条被司法或行政机关判定废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。<br/>
            上述条款将受中华人民共和国法律的约束并依据其解释。如出现纠纷，用户和Path一致同意将纠纷交由当地人民法院管辖。如用户对本服务条款内容有任何疑问，可发邮件至(hipathteam@Gmail.com)获取相关信息。</p>
            `,
    };
  },
};
</script>

<style scoped>
h3 {
  font-size: 20px;
  font-weight: 400;
  color: #75797e;
}

.contentContainer {
  background-color: transparent;
  max-width: 44rem;
  margin: 0 auto;
  padding: 30px;
  margin-top: 50px;
}

.content {
  color: #75797e;
  font-size: 16px;
  font-weight: 300;
}
</style>
