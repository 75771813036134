<template>
  <div>
    <routerView></routerView>
  </div>
</template>

<script>
</script>

<style>
</style>
