<template>
    <div class="popup" @click="closePopupHandler">
        <div class="bg" @click.stop="">
            <button @click.stop="closePopupHandler" class="btnClose">
                <img src="assets/xmarkIcon.svg" alt="main image" />
            </button>
            <div class="contentContainer">
                <p class="title">欢迎预约PATH</p>
                <p class="timeLabel">2024.06.06</p>
                <div class="content">
                    <div>感谢3000多位朋友在预约期间对Path的关注、包容和鼓励，是你们给了Path前进的勇气！♥️</div>
                    <div>
                        Path希望帮助用户做成想做的事，但实现目标的过程是不容易的。因此，Path诞生的目的就是帮助用户聚焦真正的目标，并摆脱与此无关的琐事，最终结合实际找到那条：实现真正目标的最优路径(The optimal path to achieving your true goal.)
                    </div>
                    <div>Path for macOS已上线，欢迎下载使用。如需预约其他平台，推荐您点击下方按钮关注我们的社媒账号完成预约，第一时间获取上线信息。</div>
                    <div>如果您没有关注的习惯，也可填写邮箱账号进行预约。我们将会在产品上线时给您发去邮件。</div>
                    <div>祝您万事胜意！</div>
                </div>
                <div>
                    <button class="btn" @click="openNewLink('macOS')">下载Path for macOS</button>
                    <button class="btn" @click="openNewLink('xiaohongshu')">小红书</button>
                    <!-- <button class="btn" @click="openNewLink('bbb')">哔哩哔哩</button> -->
                    <button class="btn" @click="openNewLink('email')"> 邮箱预约</button>
                </div>
                <p class="writerLabel">Path联合创始人 胡大炮</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ReservePopup',
    emits: ['closePopup'],
    methods: {
        closePopupHandler() {
            this.$emit("closePopup")
        },
        openNewLink(buttonName) {
            if (buttonName == 'email') {
                window.open('https://lrtt3m7f1w.feishu.cn/share/base/form/shrcnhiE0WJD9xKNFcwaKpHXdVe');
            } else if (buttonName == 'xiaohongshu') {
                window.open('https://www.xiaohongshu.com/user/profile/62bd05be000000001b025eaa');
            } else if (buttonName == 'bbb') {
                window.open('https://space.bilibili.com/366860913');
            } else if (buttonName == 'macOS') {
                window.open('https://apple.co/3UX7A1e');
            }
        }
    }
}
</script>

<style scoped>
.popup {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
}

@media screen and (max-width: 500px) {
    .popup {
        top: 54px;
    }
}

.bg {
    position: fixed;
    width: 760px;
    height: 560px;
    z-index: 9999;
    margin-top: 50px;

    /* background-color: rgba(250, 250, 250, 0.86); */
    background-color: var(--reservePopup-bg);
    backdrop-filter: blur(30px);
    box-shadow: 0px 0px 50px 2px rgba(184, 184, 184, 0.25);

    /* 边框 */
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    /* border-color: #E6E6E6; */
    border-color: var(--reservePopup-border);

    @media (prefers-color-scheme: dark) {
        box-shadow: none;
    }
}

@media screen and (max-width: 500px) {
    .bg {
        /* 边框 */
        border-color: transparent;
        border-radius: 0;
        border-bottom-width: 0.5px;
        /* border-bottom-color: #D8D8D8; */
        border-bottom-color: var(--navBar-border);

        box-shadow: none;

        width: 100%;
        height: 100%;
        margin-top: 0px;
    }
}

.contentContainer {
    /* background-color: aqua; */
    width: 100%;
    padding-top: 48px;
    padding-bottom: 86px;
    padding-left: 127px;
    padding-right: 127px;

}

@media screen and (max-width: 500px) {
    .contentContainer {
        padding: 32px;

    }
}

.title {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--reservePopup-content);
}

.timeLabel {
    font-size: 12px;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--reservePopup-content);

    @media (prefers-color-scheme: dark) {
        font-weight: 200;
    }
}

.content div {
    font-size: 14px;
    font-weight: 100;
    text-align: justify;
    margin-top: 0px;
    margin-bottom: 20px;
    color: var(--reservePopup-content);

    @media (prefers-color-scheme: dark) {
        font-weight: 200;
    }

}

.writerLabel {
    font-size: 14px;
    font-weight: 100;
    text-align: right;
    margin-top: 30px;
    margin-bottom: 0;
    color: var(--reservePopup-content);

    @media (prefers-color-scheme: dark) {
        font-weight: 200;
    }
}

.btnClose {
    background-color: transparent;
    position: absolute;
    top: 6px;
    right: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border: 0;
    width: 36px;
    height: 36px;
}

.btn {
    cursor: pointer;
    /* background-color: rgba(255, 255, 255, 0); */
    background-color: var(--reservePopup-buttonBG);
    color: #8D8C8C;
    transition: background-color 0.15s ease 0s;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 12px;
    margin-right: 10px;

    /* 边框 */
    border-radius: 100px;
    border-style: solid;
    border-width: 0.5px;
    /* border-color: #D3D3D3; */
    border-color: var(--reservePopup-buttonBorder);
}

@media screen and (min-width: 500px) {
    .btn:hover {
        /* background-color: rgba(255, 255, 255, 0.8); */
        background-color: var(--reservePopup-buttonBgHover);
        transition: background-color 0.15s ease 0s;
    }

    .btn:active {
        /* background-color: rgba(255, 255, 255, 0); */
        background-color: var(--reservePopup-buttonBG);
        transition: background-color 0.15s ease 0s;
    }
}
</style>