<template>
    <footer class="footerContainer">
        <div class="logoBottom">
            <img :src="imagePath" alt="logo image" />
        </div>

        <div class="bottomTextContainer">
            <a class="ruleText" href="">Started from the bottom. Hustle in CDC&CSC. ❤️</a>

            <div class="copyRightContainer">
                <div class="copyrightText">© 2024 Path版权所有</div>
                <a class="ruleText" @click="goToUserAgreementView">用户协议</a>
                <a class="ruleText" @click="goToPrivacyPolicyView">隐私政策</a>
            </div>

            <div class="recordContainer">
                <a class="ruleText"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51011502000670">川公网安备51011502000670号</a>
                <a class="ruleText" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2023143272号-1</a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches
        }
    },
    methods: {
        goToUserAgreementView() {
            this.$router.push('/userAgreement');
        },
        goToPrivacyPolicyView() {
            this.$router.push('/privacyPolicy');
        },
    },
    mounted() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            this.darkMode = e.matches;
        });
    },
    computed: {
        imagePath() {
            return this.darkMode ? 'assets/logoWhite3.svg' : 'assets/logoBlack3.svg';
        }
    }

}
</script>

<style scoped>
.footerContainer {
    display: flex;
    /* background-color: pink; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 340px;
}

@media screen and (max-width: 500px) {
    .footerContainer {
        margin-top: 320px;
    }
}

.logoBottom {
    scale: 1.0;
}

@media screen and (max-width: 500px) {

    .logoBottom {
        scale: 0.6;
    }
}

.bottomTextContainer {
    margin-top: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;
    /* background-color: aqua; */
}

@media screen and (max-width: 500px) {
    .bottomTextContainer {
        margin-top: 320px;
    }
}

.copyRightContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.copyrightText {
    font-size: 12px;
    /* color: #CED3DD; */
    color: var(--navBar-button);
    font-weight: 400;
    margin-right: 0px;
    margin-left: 0px;
}

.recordContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.ruleText {
    font-size: 12px;
    /* color: #CED3DD; */
    color: var(--navBar-button);
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s ease;
}

.ruleText:hover {
    color: #8c929c;
    transition: color 0.15s ease;
}
</style>