<template>
    <div>
        <HeaderView />
        <div class="contentContainer">
            <h3>Path隐私政策</h3>
            <div v-html="paragraphs" class="content"></div>
        </div>

    </div>
</template>

<script>

import HeaderView from './HeaderView.vue';

export default {

    components: {
        HeaderView
    },

    data() {
        return {
            paragraphs: `
            <p>生效日期：2024年5月1日</p>
            <h4>前言</h4>
            <p>Path 尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，Path 会按照本隐私权政策的规定使用和披露您的个人信息。但 Path 将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，Path 不会将这些信息对外披露或向第三方提供。Path 会不时更新本隐私权政策。 您在使用 Path 服务之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于 Path使用条款 中不可分割的一部分。</p>
            <h4>信息安全与信息使用</h4>
            <p>Path 不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可。Path 不允许任何无关第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何 Path 平台用户如从事上述活动，一经发现，Path 有权立即终止与该用户的服务协议。我们努力保护您的个人信息免遭未经授权的访问或更改、泄露或破坏。</p>
            <h4>信息披露</h4>
            <p>在如下情况下，Path 将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
            经您事先同意，向第三方披露；<br/>
            1. 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br/>
            2. 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br/>
            3. 如您出现违反中国有关法律、法规或者 Path 服务协议或相关规则的情况，需要向第三方披露；<br/>
            4. 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br/>
            5. 其它 Path 根据法律、法规或者网站政策认为合适的披露。</p>
            <p>本政策可能会随着时间而变更。如果您对本隐私政策有任何疑问，请发送邮件至hipathteam@Gmail.com</p>
         `
        }
    }
}

</script>

<style scoped>

h3 {
    font-size: 20px;
    font-weight: 400;
    color: #75797e;
}

.contentContainer {
    background-color: transparent;
    max-width: 44rem;
    margin: 0 auto;
    padding: 30px;
    margin-top: 50px;
}

.content {
    color: #75797e;
    font-size: 16px;
    font-weight: 300;
}
</style>