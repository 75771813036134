import { createWebHashHistory, createRouter } from "vue-router";
import home from "@/components/HomeView.vue";
import userAgreement from "@/components/UserAgreement.vue";
import privacyPolicy from "@/components/PrivacyPolicyView.vue";

const routes = [
  {
    path: "/",
    component: home,
  },
  {
    path: "/userAgreement",
    component: userAgreement,
  },
  {
    path: "/privacyPolicy",
    component: privacyPolicy,
  },
  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
