<template>
    <div>
        <transition name="popupTransition" :appear="true">
            <Popup v-show="showPopup" @closePopup="closePopup"></Popup>
        </transition>
        <button class="numberBg">
            <div class="number">
                <span class="numberEmoji">  </span>
                <span class="numberText"> 预约期间的关爱已铭记在心！L&P ❤️</span>
            </div>

        </button>
        <div>
            <h1 class="sloganText">
                做成想做的事
            </h1>
            <p class="sloganSecondText">全新理念设计的超级个体目标管理和待办神器，支持以终为始地规划目标、双向无限拆解目标和添加备用方案

</p>

        </div>
        <button class="reserveButton" @click="openMacAppStore">
            <img class="applePreOrderButton" :src="appleIconImagePath" alt="mainButton" />
            <!-- <div> -->
                
                <!-- <div class="buttonFirstLine"> -->
                    <!-- <img :src="appleIconImagePath" alt="main image" />
                    <div class="reserveButtonFirstText">预约Path</div> -->
                    
                <!-- </div> -->
            <!-- </div> -->
        </button>
        <div class="paltformContent" @click="showPopupHandle">
            <span class="platformText">mac平台已上线，点击预约其他平台</span>
        </div>
        <div class="heroImageContaner">
            <img :src="imagePath" alt="logo image" />
        </div>
    </div>
</template>

<script>

import Popup from "@/components/body/ReservePopup.vue"

export default {

    components: {
        Popup
    },

    data() {
        return {
            showPopup: false,
            darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches
        };
    },
    mounted() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            this.darkMode = e.matches;
        });
    },
    computed: {
        imagePath() {
            return this.darkMode ? 'assets/heroBlack.png' : 'assets/heroWhite.png';
        },
        appleIconImagePath() {
            // return this.darkMode ? 'assets/appleIconBlack.svg' : 'assets/appleIconWhite.svg';
            return this.darkMode ? 'assets/orderWhite.svg' : 'assets/orderBlack.svg';
        }
    },
    methods: {
        openMacAppStore() {
            const url = 'https://apple.co/3UX7A1e';
            window.open(url, '_blank');
        },
        showPopupHandle() {
            this.showPopup = true;
            if (window.innerWidth < 500) {
                this.disableScroll();
            }
        },
        disableScroll() {
            document.documentElement.style.overflowY = 'hidden';
        },
        enableScoll() {
            document.documentElement.style.overflowY = 'auto';
        },
        closePopup() {
            this.showPopup = false;
            if (window.innerWidth < 500) {
                this.enableScoll()
            }
        }

    },
}
</script>

<style scope>
.numberBg {
    background-color: var(--hero-numberBg);
    border-radius: 100px;
    margin-top: 180px;
    margin-bottom: 0px;

    border-style: solid;
    border-width: 0.5px;
    border-color: var(--hero-numberBorder);
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 0;
}

.number {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    
   
}
.numberEmoji {
    font-size: 14px;
    text-align: center;
    margin-left: 12px;
}

.numberText {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(to right, #fd5634, #e41d77);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    margin-right: 12px;
}

.sloganText {
    text-align: center;
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.4em;
    /* margin-top: 240px; */
    margin-top: 30px;
    margin-bottom: 20px;
    color: var(--hero-title);
}

@media screen and (max-width: 800px) {
    .sloganText {
        /* margin-top: 200px; */
        font-size: 42px;
    }
}

.sloganSecondText {
    font-size: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px;
    /* color: #8A8E95; */
    color: var(--hero-secondTitle);
    margin-left: 44px;
    margin-right: 44px;

}

@media screen and (max-width: 800px) {
    .sloganSecondText {
        font-size: 16px;
        margin-left: 44px;
        margin-right: 44px;
    }
}

.reserveButton {
    /* background-color: black; */
    /* background-color: var(--reserveButton-bg); */
    padding: 0px;
    border-radius: 10px;
    border: 0;
    display: block;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 12px;
    box-shadow: none;
    scale: 1.0;
    transition: scale 0.20s ease 0s, box-shadow 0.20s ease 0s;
}

@media screen and (min-width: 500px) {
    .reserveButton:hover {
        box-shadow: 0px 0px 12px 4px rgba(93, 93, 93, 0.34);
        transition: scale 0.20s ease 0s, box-shadow 0.20s ease 0s;
        scale: 1.04;
    }

    .reserveButton:active {
        box-shadow: none;
        transition: scale 0.20s ease 0s, box-shadow 0.20s ease 0s;
        scale: 1.0;
    }
}

.applePreOrderButton {
    scale: 1.3;
}

.buttonFirstLine {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 58px;
    margin-right: 58px;

}

.reserveButtonFirstText {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-align: center;
    /* color: white; */
    color: var(--reserveButton-title);
}

.paltformContent {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-top: 0;
    margin-bottom: -60px;
    cursor: pointer;
    text-align: center;
    /* color: #AFB4BE; */
    color: var(--navBar-button);
    transition: color 0.15s ease 0s;
}

.paltformContent:hover {
    color: #8c929c;
    transition: color 0.15s ease 0s;
}

@media screen and (max-width: 800px) {
    .paltformContent {
        margin-bottom: 44px;
    }
}

.heroImageContaner {
    display: flex;
    justify-content: center;

    margin-top: 120px;
    margin-bottom: 0;
    padding: 12px;
    /* background-color: antiquewhite; */
}

@media screen and (max-width: 800px) {
    .heroImageContaner {
        margin-top: 60px;
    }
}

.heroImageContaner img {
    display: block;
    width: 100%;
    box-shadow: 0px 6px 12px 6px rgba(93, 93, 93, 0.34);
    transition: all 0.15 ease 0s;

    max-width: 1120px;
    border-radius: 10px;

    @media (prefers-color-scheme: dark) {
        box-shadow: none;
        border-radius: 8px;
        transition: all 0.15 ease 0s;
    }
}

@media screen and (max-width: 800px) {
    .heroImageContaner img {
        max-width: none;
        border-radius: 4px;
    }
}

.popupTransition-enter-active {
    animation: showFormBottom 0.5s cubic-bezier(0.00, 0.00, 0.40, 1.00);
}

.popupTransition-leave-active {
    animation: showFormBottom 0.5s cubic-bezier(0.00, 0.00, 0.40, 1.00) reverse;
}

@keyframes showFormBottom {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0px);
    }
}
</style>